import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#E5E5E5',
      neutralLight2: '#E3E3E3',
      neutralDark7: '#707070',
      neutralDark6: '#3D4246',
      neutralDark5: '#2F2F2F',
      neutralDark4: '#2B2A2A',
      neutralDark3_1: '#262527',
      neutralDark3: '#222222',
      neutralDark2: '#141B35',
      neutralDark1: '#000000',
      primaryDark: '#324069',
      primaryLight: '#0065FF',
      primaryLight2: '#5c96d1',
      secondaryDark: '#4D9BA8',
      secondaryLight: '#2DA1BA',
      tertiaryDark: '#9242D6',
      tertiaryLight: '#9E3CDE',
      buttonGradient1: '#0A86B8',
      buttonGradient2: '#600FF5',
      buttonGradient3: '#891ADD',
      dangerLight: '#CC2121',
    },
  },
  fontFamily: {
    heading: "'DM Serif Display', serif",
    paragraph: "'Plus Jakarta Sans', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
