exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-crs-page-index-tsx": () => import("./../../../src/templates/CrsPage/index.tsx" /* webpackChunkName: "component---src-templates-crs-page-index-tsx" */),
  "component---src-templates-digital-page-index-tsx": () => import("./../../../src/templates/DigitalPage/index.tsx" /* webpackChunkName: "component---src-templates-digital-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-portfolio-page-index-tsx": () => import("./../../../src/templates/PortfolioPage/index.tsx" /* webpackChunkName: "component---src-templates-portfolio-page-index-tsx" */),
  "component---src-templates-project-page-index-tsx": () => import("./../../../src/templates/ProjectPage/index.tsx" /* webpackChunkName: "component---src-templates-project-page-index-tsx" */),
  "component---src-templates-socialmedia-page-index-tsx": () => import("./../../../src/templates/SocialmediaPage/index.tsx" /* webpackChunkName: "component---src-templates-socialmedia-page-index-tsx" */),
  "component---src-templates-team-page-index-tsx": () => import("./../../../src/templates/TeamPage/index.tsx" /* webpackChunkName: "component---src-templates-team-page-index-tsx" */),
  "component---src-templates-webmarketing-page-index-tsx": () => import("./../../../src/templates/WebmarketingPage/index.tsx" /* webpackChunkName: "component---src-templates-webmarketing-page-index-tsx" */),
  "component---src-templates-websites-page-index-tsx": () => import("./../../../src/templates/WebsitesPage/index.tsx" /* webpackChunkName: "component---src-templates-websites-page-index-tsx" */),
  "component---src-templates-works-page-index-tsx": () => import("./../../../src/templates/WorksPage/index.tsx" /* webpackChunkName: "component---src-templates-works-page-index-tsx" */)
}

